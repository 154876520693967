import axios from "axios";
import React, { useEffect, useState } from "react";
const { REACT_APP_API_URL } = process.env;

export default function EventNameEdit({ user, admin }) {
	// const variables
	const name = (user.guest === 1) ? user.Guest.name : user.User.name;
	const inputStyle = {
		maxWidth: "100%",
	};

	// useState variables
	const [value, setValue] = useState(name);
	const [processing, setProcessing] = useState(false);
	const [error, setError] = useState(null);

	// functions
	function changeHandler(e) {
		const value = e.target.value;
		setValue(value);

		if (error) {
			setError(null);
		}
	}

	function editName(value) {
		if (value === "" || value === null) {
			setError("Name cannot be empty");
			return;
		}

		setProcessing(true);

		axios.put(`${REACT_APP_API_URL}users/editname`, {
			guest: user.guest,
			userId: (user.guest === 1) ? user.GuestId : user.UserId,
			name: value,
		}, {
			headers: {
				accessToken: localStorage.getItem("accessToken"),
			},
		}).then((res) => {
			console.log(res.data);
			setProcessing(false);
		});
	}

	// useEffects
	// Use effect to detect for when user stops writing, so we can send a request to the backend
	useEffect(() => {
		// check, if current value is the same as start value, then do nothing
		if (value === name) return;

		const timeoutID = setTimeout(() => {
			editName(value);
		}, 2000);
		return () => clearTimeout(timeoutID);
	}, [value]);

	// if user is not admin, then we dont give him option to edit
	if (!admin) {
		return <td>{name}</td>;
	}

	return (
		<>
			{(user.guest === 0) ? <td>{name}</td> : (
				<td>
					<input
						disabled={processing}
						value={value}
						style={error ? { ...inputStyle, border: "1px solid red" } : inputStyle}
						onChange={changeHandler}
					/>
					{error && <p style={{ color: "red" }}>{error}</p>}
				</td>
			)}
		</>
	);
}
