import React, { useEffect, useState } from "react";

// changeObservations( => updateComment
//     e.target.value,
//     user.id,
// )

const CommentInput = ({ value, setValue, user, processing }) => (
	<input
		type="text"
		className="width45"
		placeholder="Comment"
		value={value}
		id={user.id}
		onChange={(e) => setValue(e.target.value)}
		disabled={processing}
	/>
);

export default function CommentEdit({ user, admin, updateComment, authState }) {
	const memberId = (user.guest === 0) ? user.User.id : user.Guest.id;

	const [value, setValue] = useState(user.observations);
	const [processing, setProcessing] = useState(false);

	const editComment = async () => {
		setProcessing(true);

		await updateComment(
			value,
			user.id,
		);

		setProcessing(false);
	};

	useEffect(() => {
		// check, if current value is the same as start value, then do nothing
		const timeoutID = setTimeout(() => {
			editComment(value);
		}, 2000);
		return () => clearTimeout(timeoutID);
	}, [value]);

	if (admin || memberId === authState.id) {
		return (
			<td>
				{/* Normal comment input */}
				<CommentInput value={value} setValue={setValue} processing={processing} user={user} />
			</td>
		);
	} else {
		return (
			<td>
				{/* Processing set to true to make comment input as disabled */}
				<CommentInput value={value} setValue={() => {}} processing={true} user={user} />
			</td>
		);
	}
}
