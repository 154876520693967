import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import SelectBox from "./SelectBox";
import AddGuest from "./AddGuest";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../helpers/AuthContext";
import { CSVLink } from "react-csv";
import { CSVReader } from "react-papaparse";
import * as XLSX from "xlsx";
import { useDropzone } from "react-dropzone";
import ReactExport from "react-export-excel";
// import { PDFDownloadLink, Document, Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import { Oval } from "react-loader-spinner";
import * as docx from "docx";
import { saveAs } from "file-saver";
import { Formik, Form, Field, } from "formik";

// React Bootstrap Components
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";
import EventHcpEdit from "./Components/EventHcpEdit";
import EventNameEdit from "./Components/EventNameEdit";
import CommentEdit from "./Components/CommentEdit";
const { REACT_APP_API_URL } = process.env;

const baseStyle = {
  alignItems: "center",
  borderStyle: "dashed",
  borderWidth: "2px",
  borderRadius: "20px",
  borderColor: "rgb(204, 204, 204)",
  display: "flex",
  flexDirection: "column",
  height: "140px",
  justifyContent: "center",
  padding: "20px",
  marginTop: "30px",
  marginBottom: "30px",
  cursor: "default",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

let dataSet = [];

// // Create styles
// const styles = StyleSheet.create({
//   page: {
//     flexDirection: 'row',
//     backgroundColor: '#E4E4E4'
//   },
//   section: {
//     margin: 10,
//     padding: 10,
//     flexGrow: 1
//   }
// });

function Event() {
  let { id } = useParams();
  const [listOfGroupTypes, setListOfGroupTypes] = useState([]);
  const [eventObject, setEventObject] = useState([]);
  const [listOfEventUsers, setEventUsers] = useState([]);
  const [sortedUsers, setSortedUsers] = useState([]);
  const [sortMode, setSortMode] = useState(["", "default"]);
  const [listOfEventUsersOnWaitlist, setEventUsersOnWaitlist] = useState([]);
  const [placesTotal, setPlacesTotal] = useState("");
  const [placesAvailable, setPlacesAvailable] = useState("");
  const [placesOccupied, setPlacesOccupied] = useState("");
  const [placesWaitlist, setPlacesWaitlist] = useState("");
  const [userGroups, setUserGroups] = useState([]);
  const [admin, setAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  let history = useHistory();
  const { authState, setAuthState } = useContext(AuthContext);
  const csvLink = useRef();

  const [transactionData, setTransactionData] = useState([]);
  const [items, setItems] = useState([]);

  const [visibleAlert, setVisibleAlert] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [variant, setVariant] = useState("success");
  const [dishes, setDishes] = useState({
    starter: [],
    main: [],
    desert: [],
  })

  const [visibleAddGuestForm, setVisibleAddGuestForm] = useState(false);

  const [autoUpdate, setAutoUpdate] = useState(false);

  let savedFlight = null;
  let totalHcp = 0;

  var today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = today.getFullYear();

  today = mm + "/" + dd + "/" + yyyy;
  today = dd + mm + yyyy;
  

  useEffect(() => {
    // Obtain authenticated user
    
      (async () => {
        await axios
          .get(`${REACT_APP_API_URL}users/auth`, {
            headers: {
              accessToken: localStorage.getItem("accessToken"),
            },
          })
          .then((response) => {
            if (response.data.error) {
              setAuthState({ ...authState, status: false });
              history.push(`/users/login`);
            } else {
              setAuthState({
                name: response.data.name,
                id: response.data.id,
                privileges: response.data.privileges,
                status: true,
              });
              if (response.data.privileges === "Admin") {
                setAdmin(true);
              }
              // Obtain Groups that the User belongs
              getUserGroups(response.data.id);
              // Obtain details of the event
              axios
                .get(`${REACT_APP_API_URL}events/byId/${id}`, {
                  headers: {
                    accessToken: localStorage.getItem("accessToken"),
                  },
                })
                .then((response) => {
                  setEventObject(response.data);
                  setPlacesTotal(response.data.places_total);
                  setPlacesAvailable(response.data.places_available);
                  setPlacesOccupied(response.data.places_occupied);
                  setPlacesWaitlist(response.data.places_waitlist);
                  //checkUserAccess();
                });

                // Obtain list of dishes
              axios
              .get(`${REACT_APP_API_URL}dishes/byId/${id}`, {
                headers: {
                  accessToken: localStorage.getItem("accessToken"),
                },
              })
              .then(({data}) => {
                console.log(data);
                const starter = data.filter(dish => dish.Type === 'starter')
                const main = data.filter(dish => dish.Type === 'main')
                const desert = data.filter(dish => dish.Type === 'desert')
                setDishes({
                  starter,
                  main,
                  desert
                })
              });
              // Obtain participants enrolled in the event (participants)
              axios
                .get(`${REACT_APP_API_URL}eventusers/${id}`, {
                  headers: {
                    accessToken: localStorage.getItem("accessToken"),
                  },
                })
                .then((response) => {
                  setEventUsers(response.data);
                });
              // Obtain participants enrolled in the event (waitlist)
              axios
                .get(`${REACT_APP_API_URL}eventusers/waitlist/${id}`, {
                  headers: {
                    accessToken: localStorage.getItem("accessToken"),
                  },
                })
                .then((response) => {
                  setEventUsersOnWaitlist(response.data);
                  recalculateSpots2();
                  window.scrollTo(0, 0);
                  setLoading(false);
                });
            }
          });
        setAutoUpdate(false);
        console.log("AutoUpdate", autoUpdate);
      })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    buildDataSet(listOfEventUsers);
    //recalculateSpots2();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listOfEventUsers, setEventUsers]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listOfEventUsersOnWaitlist, setEventUsersOnWaitlist]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    placesAvailable,
    setPlacesAvailable,
    placesOccupied,
    setPlacesOccupied,
    placesWaitlist,
    setPlacesWaitlist,
  ]);

  // function that returns true if user is in the group
  function checkInGroup(groupNumber){
    if (!groupNumber) return false;

    for (const group of userGroups) {
      // found group by its number, and value is 1, then user is in the group
      if (group.gid === groupNumber && group.val === 1){
        return true;
      }
    }

    // none of the groups were triggered, fallback value is false
    return false;
  }

  const getUserGroups = (id) => {
    axios
      .get(`${REACT_APP_API_URL}invitegroupusers/${id}`, {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        setUserGroups(response.data);
      });
  };

  const handleVisible = (text, variant, persist = false) => {
    window.scrollTo(0, 0);
    setAlertText(text);
    setVariant(variant);
    setVisibleAlert(true);
    if (!persist) {
      setTimeout(() => {
        setVisibleAlert(false);
      }, 2000);
    }
  };

  // Function to request to move user from participants to waitlist, or the other way around
  async function changeEventUserWaitlist(eventUserId, waitlist) {
    if (!eventUserId) return;
    if (waitlist !== true && waitlist !== false) return;

    setLoading(true);

    axios.get(`${REACT_APP_API_URL}eventusers/changewaitlist/${eventUserId}`, {
      params: {
        waitlist: waitlist
      },
      headers: {
        accessToken: localStorage.getItem("accessToken"),
      },
    }).then((res) => {
      
      // Obtain participants enrolled in the event (participants)
      axios
        .get(`${REACT_APP_API_URL}eventusers/${id}`, {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        })
        .then((response) => {
          setEventUsers(response.data);
        });
      // Obtain participants enrolled in the event (waitlist)
      axios
        .get(`${REACT_APP_API_URL}eventusers/waitlist/${id}`, {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        })
        .then((response) => {
          setEventUsersOnWaitlist(response.data);
          recalculateSpots2();
          setLoading(false);
        });

    });
    
  }

  const buildDataSet = (array) => {
    dataSet = [];
    if (array.length > 0) {
      let time = eventObject.startTime;
      let newStartTime = new Date(
        new Date("1970/01/01 " + time).getTime()
      ).toLocaleTimeString("en-UK", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });
      let newFlight = array[0].flight;
      let minsToAdd = 9;
      let tee = array[0].tee;
      const newArr = array.map((item) => {
        if (tee !== item.tee && eventObject.secondaryStartTime !== null) {
          tee = item.tee;
          time = eventObject.secondaryStartTime;
          newStartTime = new Date(
            new Date("1970/01/01 " + time).getTime()
          ).toLocaleTimeString("en-UK", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          });
          newFlight = item.flight;
        } else {
          if (newFlight !== item.flight) {
            newFlight = item.flight;
            time = newStartTime;
            newStartTime = new Date(
              new Date("1970/01/01 " + time).getTime() + minsToAdd * 60000
            ).toLocaleTimeString("en-UK", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
            });
          }
        }
        if (item.guest === 0) {
          return {
            name: item.User.name,
            federation: item.User.federation,
            tee: item.tee,
            startTime: newStartTime,
            hcp: item.User.handicap,
          };
        } else {
          return {
            name: item.Guest.name,
            federation: item.Guest.federation,
            tee: item.tee,
            startTime: newStartTime,
            hcp: item.Guest.handicap,
          };
        }
      });


      newArr.forEach((participant) => {
        const name = participant.name;
        const table = participant.table;
        const federation = participant.federation;
        const tee = participant.tee;
        const startTime = participant.startTime;
        const hcp = participant.hcp;

        dataSet.push({
          name: name,
          federation: federation,
          tee: tee,
          table: table,
          startTime: startTime,
          hcp: hcp,
        });
      });
    }
  };

  // const getTransactionData = async () => {
  //   // Obtain participants enrolled in the event (UserId)
  //   await axios
  //     .get(`${REACT_APP_API_URL}eventusers/${id}`, {
  //       headers: {
  //         accessToken: localStorage.getItem("accessToken"),
  //       },
  //     })
  //     .then((r) => {
  //       const array = [r.data];
  //       let time = eventObject.startTime;
  //       let newStartTime = new Date(
  //         new Date("1970/01/01 " + time).getTime()
  //       ).toLocaleTimeString("en-UK", {
  //         hour: "2-digit",
  //         minute: "2-digit",
  //         hour12: false,
  //       });
  //       let newFlight = array[0][0].flight;
  //       let minsToAdd = 9;
  //       let tee = array[0][0].tee;
  //       const newArr = array[0].map((item) => {
  //         if (tee !== item.tee && eventObject.secondaryStartTime !== null) {
  //           tee = item.tee;
  //           time = eventObject.secondaryStartTime;
  //           newStartTime = new Date(
  //             new Date("1970/01/01 " + time).getTime()
  //           ).toLocaleTimeString("en-UK", {
  //             hour: "2-digit",
  //             minute: "2-digit",
  //             hour12: false,
  //           });
  //           newFlight = item.flight;
  //         } else {
  //           if (newFlight !== item.flight) {
  //             newFlight = item.flight;
  //             time = newStartTime;
  //             newStartTime = new Date(
  //               new Date("1970/01/01 " + time).getTime() + minsToAdd * 60000
  //             ).toLocaleTimeString("en-UK", {
  //               hour: "2-digit",
  //               minute: "2-digit",
  //               hour12: false,
  //             });
  //           }
  //         }

  //         if (item.guest === 0) {
  //           return {
  //             NOMBRE1: item.User.name,
  //             LICENCIA1: item.User.federation,
  //             TEE: item.tee,
  //             HORA: newStartTime,
  //             HCP: item.User.handicap,
  //           };
  //         } else {
  //           return {
  //             NOMBRE1: item.Guest.name,
  //             LICENCIA1: item.Guest.federation,
  //             TEE: item.tee,
  //             HORA: newStartTime,
  //             HCP: item.Guest.handicap,
  //           };
  //         }
  //       });
  //       setTransactionData(newArr);
  //     })
  //     .catch((e) => console.log(e));
  //   csvLink.current.link.click();
  // };

  const obtainHcp = async (userid) => {
    let federationNumber = "";
    let currentHcp = 0;
    let newHcp = 0;
    // Obtain user
    await axios
      .get(`${REACT_APP_API_URL}users/byId/${userid}`)
      .then((response) => {
        const memberId = response.data.memberId;
        federationNumber = response.data.federation;
        currentHcp = response.data.handicap;
        // Obtain handicap by webscraping
        axios
          .get(`${REACT_APP_API_URL}hcpScraping/${federationNumber}`)
          .then((response) => {
            newHcp = response.data;
            if (currentHcp !== newHcp) {
              // Save new hcp

              const data = {
                memberId: memberId,
                name: "",
                email: "",
                password: "",
                phone: "",
                handicap: newHcp,
                handicapDec: newHcp,
                federation: "",
                privileges: "",
                gender: "",
              };
              axios
                .put(
                  `${REACT_APP_API_URL}users/${userid}`,
                  { data },
                  {
                    headers: {
                      accessToken: localStorage.getItem("accessToken"),
                    },
                  }
                )
                .then((response) => {})
                .catch(function (error) {
                  if (error.response) {
                    // Request made and server responded
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                  } else if (error.request) {
                    // The request was made but no response was received
                    console.log(error.request);
                  } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log("Error", error.message);
                  }
                  handleVisible(error.response.data.msg, "danger");
                });
            }
          });
      });
  };

  const enrollUser = (userid, observations = undefined, sendToWaitlist = false, teeboxSort, table) => {
    obtainHcp(userid);

    let waitlist = 0;
    if (placesAvailable <= 0 || sendToWaitlist) {
      waitlist = 1;
    }
    axios
      .post(
        `${REACT_APP_API_URL}eventusers`,
        {
          UserId: userid,
          EventId: id,
          waitlist,
          observations,
          teebox: teeboxSort,
        },
        {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        }
      )
      .then((response) => {
        axios
          .get(`${REACT_APP_API_URL}eventusers/${id}`, {
            headers: {
              accessToken: localStorage.getItem("accessToken"),
            },
          })
          .then((response) => {
            setEventUsers(response.data);
            axios
              .get(`${REACT_APP_API_URL}eventusers/waitlist/${id}`, {
                headers: {
                  accessToken: localStorage.getItem("accessToken"),
                },
              })
              .then((response) => {
                setEventUsersOnWaitlist(response.data);
              });
          });
        // Change values
        let newPlacesAvailable = placesAvailable;
        let newPlacesOccupied = placesOccupied;
        let newPlacesWaitlist = placesWaitlist;
        if (placesAvailable > 0) {
          newPlacesAvailable = placesAvailable - 1;
          newPlacesOccupied = placesOccupied + 1;
        } else {
          newPlacesWaitlist = placesWaitlist + 1;
        }
        // Update events table to change participants numbers
        axios
          .put(
            `${REACT_APP_API_URL}events/${id}`,
            {
              places_available: newPlacesAvailable,
              places_occupied: newPlacesOccupied,
              places_waitlist: newPlacesWaitlist,
            },
            {
              headers: {
                accessToken: localStorage.getItem("accessToken"),
              },
            }
          )
          .then((response) => {});
        // Change state values
        setPlacesAvailable(newPlacesAvailable);
        setPlacesOccupied(newPlacesOccupied);
        setPlacesWaitlist(newPlacesWaitlist);
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (
            error.response.data.errors[0].message ===
            "event_users__event_id__user_id must be unique"
          ) {
            handleVisible("User already enroled", "danger");
          }
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      });
    window.scrollTo(0, 0);
    recalculateSpots2();
  };

  const sendInviteMail = () => {
    history.push(`/sendInviteMail/${eventObject.id}`);
  };

  const sendParticipantsMail = () => {
    history.push(`/sendParticipantsEmail/${eventObject.id}`);
  };

  const es6Function = (value) => {
    if (value !== "addGuest") {
      enrollUser(value);
    } else {
      setVisibleAddGuestForm(true);
    }
  };

  const returnFromAddGuest = () => {
    window.scrollTo(0, 0);
    reloadParticipantsListChanges();
    setVisibleAddGuestForm(false);
  };

  const enrollUserButton = () => {
    enrollUser(authState.id);
  };

  const editEvent = () => {
    history.push(`/editevent/${id}`);
  };

  const changeFlight = async (value, eventUsersId) => {
    if (value !== "") {
      const data = {
        flight: value,
      };

      axios
        .put(`${REACT_APP_API_URL}eventusers/${eventUsersId}`, data, {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        })
        .then((response) => {
          if (autoUpdate) {
            reloadParticipantsListChanges();
          }
        })
        .catch(function (error) {
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });
    }
  };

  const changeTee = (value, eventUsersId) => {
    if (value !== "") {
      const data = {
        tee: value,
      };

      axios
        .put(`${REACT_APP_API_URL}eventusers/${eventUsersId}`, data, {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        })
        .then((response) => {
          if (autoUpdate) {
            reloadParticipantsListChanges();
          }
        })
        .catch(function (error) {
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });
    }
  };

  const changeObservations = (value, eventUsersId) => {
    
    const data = {
      observations: value,
    };


    axios
      .put(`${REACT_APP_API_URL}eventusers/${eventUsersId}`, data, {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((response) => {})
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      });
    
  };
  const changePlaces = (value, eventUsersId) => {
    if (value !== "") {
      const data = {
        places: value,
      };
 

      axios
        .put(`${REACT_APP_API_URL}eventusers/${eventUsersId}`, data, {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        })
        .then((response) => {})
        .catch(function (error) {
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });
    }
  };


  const changeStarter = (value, eventUsersId) => {
    if (value !== "") {
      const data = {
        starter: value,
      };
 

      axios
        .put(`${REACT_APP_API_URL}eventusers/${eventUsersId}`, data, {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        })
        .then((response) => {})
        .catch(function (error) {
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });
    }
  };

  const changeMain = (value, eventUsersId) => {
    if (value !== "") {
      const data = {
        main: value,
      };
 

      axios
        .put(`${REACT_APP_API_URL}eventusers/${eventUsersId}`, data, {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        })
        .then((response) => {})
        .catch(function (error) {
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });
    }
  };

  const changeDesert = (value, eventUsersId) => {
    if (value !== "") {
      const data = {
        desert: value,
      };
 

      axios
        .put(`${REACT_APP_API_URL}eventusers/${eventUsersId}`, data, {
          headers: {
            accessToken: localStorage.getItem("accessToken"),
          },
        })
        .then((response) => {})
        .catch(function (error) {
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
        });
    }
  };



  const reloadParticipantsListChanges = async () => {
    // Obtain participants enrolled in the event (UserId)
    axios
      .get(`${REACT_APP_API_URL}eventusers/${id}`, {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        setEventUsers(response.data);
        axios
          .get(`${REACT_APP_API_URL}eventusers/waitlist/${id}`, {
            headers: {
              accessToken: localStorage.getItem("accessToken"),
            },
          })
          .then((response) => {
            setEventUsersOnWaitlist(response.data);
            //recalculateSpots2();
          });
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      });
  };

  const removeParticipant = (eventUsersId, userId, guest) => {
    // Check to see if comment is not blank
    axios
      .get(`${REACT_APP_API_URL}eventusers/byid/${eventUsersId}`, {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        const observations = response.data.observations;
        if (
          observations !== "" &&
          observations !== undefined &&
          observations !== null
        ) {
          axios
            .delete(`${REACT_APP_API_URL}eventusers/${eventUsersId}`, {
              headers: {
                accessToken: localStorage.getItem("accessToken"),
              },
            })
            .then((response) => {
              axios
                .get(`${REACT_APP_API_URL}eventusers/${id}`, {
                  headers: {
                    accessToken: localStorage.getItem("accessToken"),
                  },
                })
                .then((response) => {
                  setEventUsers(response.data);
                  axios
                    .get(`${REACT_APP_API_URL}eventusers/waitlist/${id}`, {
                      headers: {
                        accessToken: localStorage.getItem("accessToken"),
                      },
                    })
                    .then((response) => {
                      setEventUsersOnWaitlist(response.data);
                    });
                });
              // Change values
              let newPlacesAvailable = placesAvailable;
              let newPlacesOccupied = placesOccupied;
              let newPlacesWaitlist = placesWaitlist;
              if (placesWaitlist > 0) {
                newPlacesWaitlist = placesWaitlist - 1;
              } else {
                newPlacesAvailable = placesAvailable + 1;
                newPlacesOccupied = placesOccupied - 1;
              }
              // Update events table to change participants numbers
              axios
                .put(
                  `${REACT_APP_API_URL}events/${id}`,
                  {
                    places_available: newPlacesAvailable,
                    places_occupied: newPlacesOccupied,
                    places_waitlist: newPlacesWaitlist,
                  },
                  {
                    headers: {
                      accessToken: localStorage.getItem("accessToken"),
                    },
                  }
                )
                .then((response) => {
                  if (guest === 0) {
                    sendParticipantRemoveMail(userId, observations);
                    sendOrganizerRemoveMail(userId,observations)
                  }
                });
              // Change state values
              setPlacesAvailable(newPlacesAvailable);
              setPlacesOccupied(newPlacesOccupied);
              setPlacesWaitlist(newPlacesWaitlist);
            })
            .catch(function (error) {
              if (error.response) {
                // Request made and server responded
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
            });
        } else {
          handleVisible(
            "Please provide comments if you would like to delete",
            "danger"
          );
        }
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      });
    recalculateSpots2();
    history.push(`/events/byid/${id}`);
  };

  const sendParticipantRemoveMail = (userId, observations) => {
    // Obtain user email
    axios.get(`${REACT_APP_API_URL}users/byId/${userId}`).then((response) => {
      const userRecipientMail = response.data.email;
      const sender = authState.id;
      const subject = `El Paraiso Golf - Participant Deleted from Event ${eventObject.event}`;
      const message = `${eventObject.event} - ${new Date(
        eventObject.date
      ).toLocaleDateString()} - ${window.location.href}`;

      let output = `
        <h3>El Paraiso Golf Club</h3>
        <p>You are deleted from the following event:</p>
        <ul>  
          <li>Event: ${eventObject.event}</li>
          <li>Course: ${eventObject.course}</li>
          <li>Date: ${new Date(eventObject.date).toLocaleDateString()}</li>
          <li>Start: ${String(eventObject.startTime).slice(0, 5)}</li>
          <li>Link: <a href="${window.location.href}">${
        window.location.href
      }</a></li>
        <ul>
        <p>Motive: ${observations}</p>
      `;

      axios
        .post(
          `${REACT_APP_API_URL}mail/sendcancelationmail`,
          {
            subject,
            message,
            output,
            userRecipientMail,
            sender,
          },
          {
            headers: {
              accessToken: localStorage.getItem("accessToken"),
            },
          }
        )
        .then((response) => {
          handleVisible("Participant deleted", "success");
        })
        .catch(function (error) {
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
          handleVisible(error.response.data.msg, "danger");
        });
    });
  };

  const sendOrganizerRemoveMail = (userId, observations) => {
    // Obtain user email
    axios.get(`${REACT_APP_API_URL}users/byId/${userId}`).then((response) => {
      const userRecipientMail = response.data.email;
      const sender = authState.id;
      const subject = `El Paraiso Golf - Participant Deleted from Event ${eventObject.event}`;
      const message = `${eventObject.event} - ${new Date(
        eventObject.date
      ).toLocaleDateString()} - ${window.location.href}`;

      let output = `
        <h3>El Paraiso Golf Club</h3>
        <p>The participant with email ${userRecipientMail} is deleted from the following event:</p>
        <ul>  
          <li>Event: ${eventObject.event}</li>
          <li>Course: ${eventObject.course}</li>
          <li>Date: ${new Date(eventObject.date).toLocaleDateString()}</li>
          <li>Start: ${String(eventObject.startTime).slice(0, 5)}</li>
          <li>Link: <a href="${window.location.href}">${
        window.location.href
      }</a></li>
        <ul>
        <p>Motive: ${observations}</p>
      `;

      axios
        .post(
          `${REACT_APP_API_URL}mail/sendorganizarscancelationmail`,
          {
            subject,
            message,
            output,
            userRecipientMail,
            sender,
          },
          {
            headers: {
              accessToken: localStorage.getItem("accessToken"),
            },
          }
        )
        .then((response) => {
          handleVisible("Participant deleted", "success");
        })
        .catch(function (error) {
          if (error.response) {
            // Request made and server responded
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
          handleVisible(error.response.data.msg, "danger");
        });
    });
  };

  const handleClick = (e, value) => {
    e.preventDefault();

    if (value === "0") {
      e.target.value = "";
    }
  };

  const handleBlur = (e, value) => {
    e.preventDefault();

    if (value === "") {
      e.target.value = "0";
    }
  };

  const handleOnDrop = async (data) => {
    let federation = "";
    // Cycle through data
    await Promise.all(
      data.map(async (participant) => {
        federation = participant.data;
        await axios
          .get(`${REACT_APP_API_URL}users/byfederation/${federation}`, {
            headers: {
              accessToken: localStorage.getItem("accessToken"),
            },
          })
          .then((response) => {
            enrollUser(response.data.id, participant["Observaciones"]);
          })
          .catch(function (error) {
            if (error.response) {
              // Request made and server responded
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error", error.message);
            }
          });
      })
    );

    recalculateSpots2();
  };

  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  const handleOnRemoveFile = (data) => {
    console.log("---------------------------");
    console.log(data);
    console.log("---------------------------");
  };

  const recalculateSpots2 = async () => {
    // Obtain number of total spots for the event
    await axios
      .get(`${REACT_APP_API_URL}events/byId/${id}`, {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then(async (response) => {
        const placesTotalA = response.data.places_total;
           
        // Obtain number of enrolled users (not on waitlist)
        await axios
          .get(`${REACT_APP_API_URL}eventusers/totalenrolled/${id}`, {
            headers: {
              accessToken: localStorage.getItem("accessToken"),
            },
          })
          .then(async (response) => {
            const numUsersEnrolled = response.data;

            // First case
            if (numUsersEnrolled > placesTotalA) {
              for (let i = numUsersEnrolled - placesTotalA; i > 0; i--) {
                // Read LAST user enrolled and force move to waitlist
                await axios
                  .get(
                    `${REACT_APP_API_URL}eventusers/getlastuserenrolled/${id}`,
                    {
                      headers: {
                        accessToken: localStorage.getItem("accessToken"),
                      },
                    }
                  )
                  .then((response) => {});
              } 
             } 
          });

        // Obtain number of enrolled users (not on waitlist)
        await axios
          .get(`${REACT_APP_API_URL}eventusers/totalenrolled/${id}`, {
            headers: {
              accessToken: localStorage.getItem("accessToken"),
            },
          })
          .then(async (response) => {
            const numUsersEnrolledE = response.data;
            await axios
              .get(`${REACT_APP_API_URL}eventusers/totalwaitlist/${id}`, {
                headers: {
                  accessToken: localStorage.getItem("accessToken"),
                },
              })
              .then((response) => {
                const numUsersWaitlistF = response.data;
                const numPlacesAvailable = placesTotalA - numUsersEnrolledE;

                // Update events table to change participants numbers
                axios
                  .put(
                    `${REACT_APP_API_URL}events/${id}`,
                    {
                      places_available: numPlacesAvailable,
                      places_occupied: numUsersEnrolledE,
                      places_waitlist: numUsersWaitlistF,
                    },
                    {
                      headers: {
                        accessToken: localStorage.getItem("accessToken"),
                      },
                    }
                  )
                  .then((response) => {
                    setPlacesAvailable(response.data.places_available);
                    setPlacesOccupied(response.data.places_occupied);
                    setPlacesWaitlist(response.data.places_waitlist);
                    reloadParticipantsListChanges();
                  });
              });
          });
      });
  };

  const changeSavedFlight = (flight) => {
    savedFlight = flight;
  };

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const promise = new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);

        fileReader.onload = (e) => {
          const bufferArray = e.target.result;

          const wb = XLSX.read(bufferArray, { type: "buffer" });

          const wsname = wb.SheetNames[0];

          const ws = wb.Sheets[wsname];

          const data = XLSX.utils.sheet_to_json(ws);

          resolve(data);
        };

        fileReader.onerror = (error) => {
          reject(error);
        };
      });

      promise.then((d) => {
        setItems(d);
      });
    });
  }, []);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop });

  useEffect(() => {
    let federation = "";
    // Cycle through data
    (async () => {
      const { data:teeboxInfo} = await axios
      .get(`${REACT_APP_API_URL}teeboxInfo/` , {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        }})
        for (const participant of items) {
        federation = participant["Licencia"];
        let colors = teeboxInfo.find(color => color.teeboxcolor?.toLowerCase().trim() === participant["Barras"]?.toLowerCase().trim());
        let ans;
        if(colors) {
          ans = colors.teeboxno
         } else {
         ans = 0
         }
        await axios
          .get(`${REACT_APP_API_URL}users/byfederation/${federation}`, {
            headers: {
              accessToken: localStorage.getItem("accessToken"),
            },
          })
          .then((response) => {
            if (response.data) {
              enrollUser(response.data.id, participant["Observaciones"], participant['L.E'] === 'S', ans);
            } else {
              // Here it should add the user as a guest
              addGuest(participant, ans);
            }
          })
          .catch(function (error) {
            console.log(error);
            if (error.response) {
              // Request made and server responded
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error", error.message);
            }
          });
        };
     })();
    

    recalculateSpots2();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);


  const addGuest = (participant, teeboxSort) => {

    let guestId = 0;

    //const nameArr = participant['Nombre'].split(',');

    // let firstName = nameArr[1].toLowerCase();
    // firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
    // let lastName = nameArr[0].toLowerCase();
    // lastName = lastName.charAt(0).toUpperCase() + lastName.slice(1);

    // const finalName = `${firstName} ${lastName}`;

    const data = {
      name: participant["Nombre"],
      gender: participant['S'],
      email: "",
      handicap: participant["Hcp"],
      handicapDec: participant["Hcp"],
      federation: participant["Licencia"],
      EventId: id,
    };

    // Add Guest to Guests table
    axios
      .post(`${REACT_APP_API_URL}guests/`, data, {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then(async (response) => {
        guestId = response.data;

        // Obtain available spots for the Event
        await axios
          .get(`${REACT_APP_API_URL}events/byId/${id}`, {
            headers: {
              accessToken: localStorage.getItem("accessToken"),
            },
          })
          .then(async (response) => {
            let waitlist = 1;
            if (response.data.places_available > 0 && participant['L.E'] !== 'S') {
              waitlist = 0;
            } 
            let observations = "Guest";
            if (participant["Observaciones"]) {
              observations += ", " + participant["Observaciones"];
            }
            // Add registry to EventUsers table with indication that it's a guest
            await axios
              .post(
                `${REACT_APP_API_URL}eventusers`,
                {
                  GuestId: guestId,
                  EventId: id,
                  guest: 1,
                  waitlist,
                  observations: observations,
                  teebox: teeboxSort,
                },
                {
                  headers: {
                    accessToken: localStorage.getItem("accessToken"),
                  },
                }
              )
              .then((response) => {
              });
          });
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      });
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  // const MyDoc = () => (
  //   <Document>
  //     <Page>
  //       <View style={styles.section}>
  //         <Text>Section #1</Text>
  //       </View>
  //       <View style={styles.section}>
  //         <Text>Section #2</Text>
  //       </View>
  //     </Page>
  //   </Document>
  // );

  const resetTotalHcp = () => {
    totalHcp = 0;
  };

  const addHcp = (hcp) => {
    let decimalNumber = hcp.replace(/,/g, ".");
    totalHcp += Math.round(parseFloat(decimalNumber) * 10) / 10;
  };

  const showHcp = () => {
    const totalHcpFixed = totalHcp.toFixed(1);
    return totalHcpFixed.toString().replace(".", ",");
  };

  const toggleAutoUpdate = () => {
    setAutoUpdate(!autoUpdate);
  };

  const autoGroupParticipants = () => {
    let flightNumber = -1;
    const numberOfGroups = Math.ceil(listOfEventUsers.length / 4.0);
    setLoading(true);
    const sortedList = listOfEventUsers.sort((a, b) => {
      const aHcp = a.User ? a.User.handicap : a.Guest.handicap;
      const bHcp = b.User ? b.User.handicap : b.Guest.handicap;
      return Number(aHcp.replace(/,/g, ".")) - Number(bHcp.replace(/,/g, "."));
    });
    setEventUsers(sortedList)

    for (let i = 0; i < sortedList.length; i += numberOfGroups) {
        sortedList
        .slice(i, i + numberOfGroups)
        .map(async (participant) => {
          flightNumber += 1;
          await changeFlight(
            (flightNumber % numberOfGroups) + 1,
            participant.id
          );
        });
    }
    setLoading(false);
    reloadParticipantsListChanges();
  };

  const getParticipantsDocx = () => {
    let participantsOutput = [];
    let time = eventObject.startTime;
    let newStartTime = new Date(
      new Date("1970/01/01 " + time).getTime()
    ).toLocaleTimeString("en-UK", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
    console.log("listOfEventUsers", listOfEventUsers);
    let newFlight = listOfEventUsers[0].flight;
    let newTee = listOfEventUsers[0].tee;
    let minsToAdd = 9;
    let totalHcp = 0;

    listOfEventUsers.map(async (participant) => {
      if (newTee !== participant.tee) {
        if (eventObject.secondaryStartTime !== null) {
          participantsOutput.push(
            new docx.TextRun({
              text: `Group HCP: ${totalHcp
                .toFixed(1)
                .toString()
                .replace(".", ",")}`,
              break: 1,
            })
          );
          totalHcp = 0;
          newTee = participant.tee;
          newFlight = participant.flight;
          time = eventObject.secondaryStartTime;
          newStartTime = new Date(
            new Date("1970/01/01 " + time).getTime()
          ).toLocaleTimeString("en-UK", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          });
        } else {
          participantsOutput.push(
            new docx.TextRun({
              text: `Group HCP: ${totalHcp
                .toFixed(1)
                .toString()
                .replace(".", ",")}`,
              break: 1,
            })
          );
          totalHcp = 0;
          newTee = participant.tee;
          newFlight = participant.flight;
          time = eventObject.startTime;
          newStartTime = new Date(
            new Date("1970/01/01 " + time).getTime()
          ).toLocaleTimeString("en-UK", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          });
        }
      }
      if (newFlight !== participant.flight) {
        participantsOutput.push(
          new docx.TextRun({
            text: `Group HCP: ${totalHcp
              .toFixed(1)
              .toString()
              .replace(".", ",")}`,
            break: 1,
          })
        );
        totalHcp = 0;
        newFlight = participant.flight;
        time = newStartTime;
        newStartTime = new Date(
          new Date("1970/01/01 " + time).getTime() + minsToAdd * 60000
        ).toLocaleTimeString("en-UK", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        });
      }
      if (participant.User) {
        if (participant.observations) {
          participantsOutput.push(
            new docx.TextRun({
              text: `${newStartTime} - ${participant.tee} - ${participant.User.name} - ${participant.User.handicap} - ${participant.observations}`,
              break: 1,
            })
          );
        } else {
          participantsOutput.push(
            new docx.TextRun({
              text: `${newStartTime} - ${participant.tee} - ${participant.User.name} - ${participant.User.handicap}`,
              break: 1,
            })
          );
        }
        totalHcp += parseFloat(participant.User.handicap.replace(/,/, "."));
      } else {
        if (participant.observations) {
          participantsOutput.push(
            new docx.TextRun({
              text: `${newStartTime} - ${participant.tee} - ${participant.Guest.name} - ${participant.Guest.handicap} - ${participant.observations}`,
              break: 1,
            })
          );
        } else {
          participantsOutput.push(
            new docx.TextRun({
              text: `${newStartTime} - ${participant.tee} - ${participant.Guest.name} - ${participant.Guest.handicap}`,
              break: 1,
            })
          );
        }
        totalHcp += parseFloat(participant.Guest.handicap.replace(/,/, "."));
      }
    });
    if (totalHcp > 0) {
      participantsOutput.push(
        new docx.TextRun({
          text: `Group HCP: ${totalHcp
            .toFixed(1)
            .toString()
            .replace(".", ",")}`,
          break: 1,
        })
      );
    }

    return participantsOutput;
  };

  const exportDocx = () => {
    const doc = new docx.Document({
      sections: [
        {
          properties: {},
          children: [
            new docx.Paragraph({
              text: "El Paraiso Golf Event",
              heading: docx.HeadingLevel.HEADING_1,
              thematicBreak: true,
            }),
            new docx.Paragraph({
              children: [
                new docx.TextRun("Start: "),
                new docx.TextRun({
                  text: `${String(eventObject.startTime).slice(0, 5)}`,
                  bold: true,
                }),
              ],
            }),
            new docx.Paragraph({
              children: [
                new docx.TextRun("Event: "),
                new docx.TextRun({
                  text: `${eventObject.event}`,
                  bold: true,
                }),
              ],
            }),
            new docx.Paragraph({
              children: [
                new docx.TextRun("Date: "),
                new docx.TextRun({
                  text: `${new Date(eventObject.date).toLocaleDateString(
                    "es-ES"
                  )}`,
                  bold: true,
                }),
              ],
            }),
            new docx.Paragraph({
              children: [
                new docx.TextRun("Link: "),
                new docx.TextRun({
                  text: "http://elparaisoeventreg.scala4.com/events/byId/164",
                  bold: true,
                }),
              ],
            }),
            new docx.Paragraph({
              children: [new docx.TextRun("")],
            }),
            new docx.Paragraph({
              text: "Participants",
              heading: docx.HeadingLevel.HEADING_2,
              thematicBreak: false,
            }),
            new docx.Paragraph({
              children: getParticipantsDocx(),
            }),
          ],
        },
      ],
    });

    docx.Packer.toBlob(doc).then((blob) => {
      console.log(blob);
      saveAs(blob, `${eventObject.event}.docx`);
      console.log("Document created successfully");
    });
  };

  useEffect(() => {
    setTimeout(() => {
      const aux = listOfEventUsers.slice().sort((a, b) => {
        const aName = a.User ? a.User.name : a.Guest.name;
        const bName = b.User ? b.User.name : b.Guest.name;
        const aGroup = a.User ? a.User.groupType : a.Guest.groupType;
        const bGroup = b.User ? b.User.groupType : b.Guest.groupType;
        const aPlaces = a.User ? a.User.places : a.Guest.places;
        const bPlaces = b.User ? b.User.places : b.Guest.places;
        const aHcp = a.User ? a.User.handicap : a.Guest.handicap;
        const bHcp = b.User ? b.User.handicap : b.Guest.handicap;
        if (sortMode[0] === "name" && sortMode[1] === "asc") {
          return aName.localeCompare(bName);
        } else if (sortMode[0] === "name" && sortMode[1] === "desc") {
          return bName.localeCompare(aName);
        } else if (sortMode[0] === "group" && sortMode[1] === "asc") {
          return Number(aGroup) - Number(bGroup);
        } else if (sortMode[0] === "group" && sortMode[1] === "desc") {
          return Number(bGroup) - Number(aGroup);   
        } else if (sortMode[0] === "places" && sortMode[1] === "asc") {
          return Number(aPlaces) - Number(bPlaces); 
        } else if (sortMode[0] === "places" && sortMode[1] === "desc") {
          return Number(bPlaces) - Number(aPlaces);     
        }else if (sortMode[0] === "hcp" && sortMode[1] === "asc") {
          return Number(aHcp.replace(/,/g, ".")) - Number(bHcp.replace(/,/g, "."));
        } else if (sortMode[0] === "hcp" && sortMode[1] === "desc") {
          return Number(bHcp.replace(/,/g, ".")) - Number(aHcp.replace(/,/g, "."));
        } else {
          return 0;
        }
      });
      setSortedUsers(aux);
    }, 100);
  }, [sortMode, listOfEventUsers]);

  const handleSort = (column) => {
    if (column !== sortMode[0]) {
      setSortMode([column, "asc"]);
      return;
    }
    if (sortMode[1] === "default") {
      setSortMode(([column, _]) => [column, "asc"]);
    } else if (sortMode[1] === "asc") {
      setSortMode(([column, _]) => [column, "desc"]);
    } else if (sortMode[1] === "desc") {
      setSortMode(([column, _]) => [column, "default"]);
    }
  };

  return (
    <>
      <Alert show={visibleAlert} variant={variant}>
        {alertText}
      </Alert>
      {!loading &&
        (
          checkInGroup(eventObject.groupNumber)
        ) && (
          <Container className="eventPage">
            <Row>
              <Col>
                <Card
                  key={eventObject.id}
                  className="text-center mb-3 event-details"
                >
                  <Card.Header>
                    {new Date(eventObject.date).toLocaleDateString("es-ES")}{" "}
                    {String(eventObject.startTime).slice(0, 5)}
                  </Card.Header>
                  <Card.Body>
                    <Card.Title>{eventObject.event}</Card.Title>
                    <Card.Text>
                      {eventObject.type} | {eventObject.groupName}
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer className="text-muted">
                    Total: {placesTotal} | Available: {placesAvailable} |
                    Occupied: {placesOccupied} | Waitlist: {placesWaitlist}
                  </Card.Footer>
                  {admin && (
                    <Card.Footer>
                      <Button
                        variant="warning"
                        onClick={editEvent}
                        className="mt-2"
                      >
                        Edit Event
                      </Button>
                      {/* <Button
                      variant='danger'
                      onClick={recalculateSpots2}
                      className='mt-2'
                    >
                      DONOTCLICK
                    </Button> */}
                      {/* <Button
                    variant='danger'
                    onClick={sendInviteMail2}
                    className='mt-2'
                    size='sm'
                  >
                    TEST
                  </Button> */}
                    </Card.Footer>
                  )}
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card className="eventUsers">
                  <Card.Header>Participants</Card.Header>
                  <Card.Body>
                    {/*
                    <Table className="participantsTable" responsive borderless>
                      <colgroup>
                        <col span="1" className="width35" />
                        <col span="1" className="width5" />
                        <col span="1" className="width5" />
                        <col span="1" className="width5" />
                        <col span="1" className="width15" />
                        <col span="1" className="width10" />
                        <col span="1" className="width10" />
                        <col span="1" className="width10" />
                        <col span="1" className="width30" />
                        <col span="1" className="width45" />
                      </colgroup>
                      <thead>
                      */}
                    <Table className="participantsTable" responsive borderless>
                      <thead>
                        <td
                          className="sortableColumn"
                          onClick={() => handleSort("name")}
                        >
                          Name
                          {sortMode[0] === "name" && sortMode[1] === "asc" && (
                            <i className="fas fa-arrow-up black" />
                          )}
                          {sortMode[0] === "name" && sortMode[1] === "desc" && (
                            <i className="fas fa-arrow-down black" />
                          )}
                        </td>
                        <td
                          className="sortableColumn"
                          onClick={() => handleSort("group")}
                        >
                          {eventObject.groupType}
                          {sortMode[0] === "group" && sortMode[1] === "asc" && (
                            <i className="fas fa-arrow-up black" />
                          )}
                          {sortMode[0] === "group" &&
                            sortMode[1] === "desc" && (
                              <i className="fas fa-arrow-down black" />
                            )}
                        </td>

                        {eventObject.groupType !== "Tables" && (
                          <td className="sortableColumn">Tee</td>
                        )}

                        {/* space for buttons */}
                        {admin &&
                          <td></td> 
                        }

                        {eventObject.groupType !== "Tables" && (
                          <td
                            className="sortableColumn"
                            onClick={() => handleSort("hcp")}
                          >
                            HCP
                            {sortMode[0] === "hcp" && sortMode[1] === "asc" && (
                              <i className="fas fa-arrow-up black" />
                            )}
                            {sortMode[0] === "hcp" &&
                              sortMode[1] === "desc" && (
                                <i className="fas fa-arrow-down black" />
                              )}
                          </td>
                        )}

                        {eventObject.groupType === "Tables" && (
                          <td
                            className="sortableColumn"
                            onClick={() => handleSort("places")}
                          >
                            Places{" "}
                            {sortMode[0] === "places" &&
                              sortMode[1] === "asc" && (
                                <i className="fas fa-arrow-up black" />
                              )}
                            {sortMode[0] === "places" &&
                              sortMode[1] === "desc" && (
                                <i className="fas fa-arrow-down black" />
                              )}
                          </td>
                        )}
                        {eventObject.groupType === "Tables" && <td>Starter</td>}
                        {eventObject.groupType === "Tables" && <td>Main</td>}
                        {eventObject.groupType === "Tables" && <td>Desert</td>}
                        <td>Comments</td>
                        <td></td>
                      </thead>
                      <tbody>
                        {sortedUsers.map((value, key) => {
                          return (
                            <>
                              {value.flight !== savedFlight &&
                                sortMode[1] === "default" && (
                                  <>
                                    {savedFlight !== null && (
                                      <>
                                        {/* <tr className='spacer'></tr> */}
                                        {/*BK {eventObject.groupType !== "Tables" && (*/}
                                        <tr>
                                          <td colSpan="4">&nbsp;</td>
                                          {eventObject.groupType !== "Tables" && 
                                          <td>{showHcp()}</td>}
                                          
                                          <td colSpan="2">&nbsp;</td>
                                        </tr>
                                        <tr className="tableBorderTop">
                                          <td colSpan="5">{resetTotalHcp()}</td>
                                        </tr>
                                        <tr className="spacer"></tr>
                                      </>
                                    )}
                                    {/* BK why no go  )} */}
                                    {changeSavedFlight(value.flight)}
                                  </>
                                )}

                              <tr key={value.id}>
                                {/* {value.guest === 0 && (
                                  <td>{value.User.name}</td>
                                )}
                                {value.guest === 1 && (
                                  <td>{value.Guest.name}</td>
                                )} */}
                                <EventNameEdit user={value} admin={admin}/>
                                <td>
                                  {(admin || value.guest === 1) && (
                                    <input
                                      className="width45px"
                                      type="number"
                                      defaultValue={value.flight}
                                      min="0"
                                      max="9999"
                                      id={value.id}
                                      onChange={(e) =>
                                        changeFlight(e.target.value, value.id)
                                      }
                                      onClick={(e) =>
                                        handleClick(e, e.target.value)
                                      }
                                      onBlur={(e) =>
                                        handleBlur(e, e.target.value)
                                      }
                                    />
                                  )}
                                  {(!admin && value.guest === 0)&& (
                                    <input
                                      className="width45px"
                                      type="number"
                                      defaultValue={value.flight}
                                      min="0"
                                      max="9999"
                                      id={value.id}
                                      disabled
                                    />
                                  )}
                                </td>
                                {eventObject.groupType !== "Tables" && (
                                  <td>
                                    {admin && (
                                      <input
                                        className="width45px"
                                        type="number"
                                        defaultValue={value.tee}
                                        min="1"
                                        max="9999"
                                        id={value.id}
                                        onChange={(e) =>
                                          changeTee(e.target.value, value.id)
                                        }
                                        onClick={(e) =>
                                          handleClick(e, e.target.value)
                                        }
                                        onBlur={(e) =>
                                          handleBlur(e, e.target.value)
                                        }
                                      />
                                    )}

                                    {!admin && (
                                      <input
                                        className="width45px"
                                        type="number"
                                        defaultValue={value.tee}
                                        min="1"
                                        max="9999"
                                        id={value.id}
                                        disabled
                                      />
                                    )}
                                  </td>
                                )}

                                {admin &&
                                  <td className="pb-1">
                                    <button onClick={() => changeEventUserWaitlist(value.id, true)} className="btn btn-success btn-sm" >Waitlist</button>
                                  </td>
                                }

                                {eventObject.groupType !== "Tables" &&
                                  value.guest === 0 && (
                                    <>
                                      {!admin 
                                        ? <td>{value.User.handicap}</td>
                                        : <EventHcpEdit user={value}/>
                                      }
                                      
                                      <td className="hidden">
                                        {addHcp(value.User.handicap)}
                                      </td>
                                    </>
                                  )}
                                {eventObject.groupType !== "Tables" &&
                                  value.guest === 1 && (
                                    <>
                                      {!admin 
                                        ? <td>{value.Guest.handicap}</td>
                                        : <EventHcpEdit user={value}/>
                                      }

                                      <td className="hidden">
                                        {addHcp(value.Guest.handicap)}
                                      </td>
                                    </>
                                  )}

                                {eventObject.groupType === "Tables" && (
                                  <td>
                                    {(admin || value.guest === 1) && (
                                      <input
                                        className="width35px"
                                        type="number"
                                        defaultValue={value.places}
                                        min="1"
                                        max="12"
                                        id={value.id}
                                        onChange={(e) =>
                                          changePlaces(e.target.value, value.id)
                                        }
                                        onClick={(e) =>
                                          handleClick(e, e.target.value)
                                        }
                                        onBlur={(e) =>
                                          handleBlur(e, e.target.value)
                                        }
                                      />
                                    )}

                                    {(!admin && value.guest === 0) && (
                                      <input
                                        className="width35px"
                                        type="number"
                                        defaultValue={value.places}
                                        min="1"
                                        max="12"
                                        id={value.id}
                                        disabled
                                      />
                                    )}
                                  </td>
                                )}

                                 {eventObject.groupType === "Tables" && (
                                  <td>
                                    {value.guest === 0 &&
                                      (admin ||
                                        value.User.id === authState.id) && (
                                        <Formik
                                          initialValues={{
                                            starter: value.starter || null,
                                          }}
                                          validate={(values) => {
                                            changeStarter(values.starter, value.id);
                                          }}
                                        >
                                          <Field
                                            as="select"
                                            id="inputCreateDish"
                                            name="starter"
                                          >
                                            <option>{value.starter}</option>

                                            {dishes.starter.map((value, key) => {
                                              return (
                                                <option value={value.Name}>
                                                  {value.Name}
                                                </option>
                                              );
                                            })}
                                          </Field>
                                        </Formik>
                                      )}
                                    {value.guest === 1 &&
                                      (admin ||
                                        value.Guest.id === authState.id) && (
                                        <Formik
                                          initialValues={{
                                            starter: value.starter || null,
                                          }}
                                          validate={(values) => {
                                            changeStarter(values.starter, value.id);
                                          }}
                                        >
                                          <Field
                                            as="select"
                                            id="inputCreateDish"
                                            name="starter"
                                          >
                                            <option>{value.starter}</option>

                                            {dishes.starter.map((value, key) => {
                                              return (
                                                <option value={value.Name}>
                                                  {value.Name}
                                                </option>
                                              );
                                            })}
                                          </Field>
                                        </Formik>
                                      )}
                                    {value.guest === 0 &&
                                      !admin &&
                                      value.User.id !== authState.id && (
                                        <Formik
                                          initialValues={{
                                            starter: value.starter || null,
                                          }}
                                          validate={(values) => {
                                            changeStarter(values.starter, value.id);
                                          }}
                                        >
                                          <Field
                                            as="select"
                                            id="inputCreateDish"
                                            name="starter"
                                            disabled
                                          >
                                            <option>{value.starter}</option>

                                            {dishes.starter.map((value, key) => {
                                              return (
                                                <option value={value.Name}>
                                                  {value.Name}
                                                </option>
                                              );
                                            })}
                                          </Field>
                                        </Formik>
                                      )}
                                    {value.guest === 1 &&
                                      !admin &&
                                      value.Guest.id !== authState.id && (
                                        <Formik
                                          initialValues={{
                                            starter: value.starter || null,
                                          }}
                                          validate={(values) => {
                                            changeStarter(values.starter, value.id);
                                          }}
                                        >
                                          <Field
                                            as="select"
                                            id="inputCreateDish"
                                            name="starter"
                                          >
                                            <option>{value.starter}</option>

                                            {dishes.starter.map((value, key) => {
                                              return (
                                                <option value={value.Name}>
                                                  {value.Name}
                                                </option>
                                              );
                                            })}
                                          </Field>
                                        </Formik>
                                      )}
                                  </td>
                                )}
                                
                                {eventObject.groupType === "Tables" && (
                                  <td>
                                    {value.guest === 0 &&
                                      (admin ||
                                        value.User.id === authState.id) && (
                                        <Formik
                                          initialValues={{
                                            main: value.main || null,
                                          }}
                                          validate={(values) => {
                                            changeMain(values.main, value.id);
                                          }}
                                        >
                                          <Field
                                            as="select"
                                            id="inputCreateDish"
                                            name="main"
                                          >
                                            <option>{value.main}</option>

                                            {dishes.main.map((value, key) => {
                                              return (
                                                <option value={value.Name}>
                                                  {value.Name}
                                                </option>
                                              );
                                            })}
                                          </Field>
                                        </Formik>
                                      )}
                                    {value.guest === 1 &&
                                      (admin ||
                                        value.Guest.id === authState.id) && (
                                        <Formik
                                          initialValues={{
                                            main: value.main || null,
                                          }}
                                          validate={(values) => {
                                            changeMain(values.main, value.id);
                                          }}
                                        >
                                          <Field
                                            as="select"
                                            id="inputCreateDish"
                                            name="main"
                                          >
                                            <option>{value.main}</option>

                                            {dishes.main.map((value, key) => {
                                              return (
                                                <option value={value.Name}>
                                                  {value.Name}
                                                </option>
                                              );
                                            })}
                                          </Field>
                                        </Formik>
                                      )}
                                    {value.guest === 0 &&
                                      !admin &&
                                      value.User.id !== authState.id && (
                                        <Formik
                                          initialValues={{
                                            main: value.main || null,
                                          }}
                                          validate={(values) => {
                                            changeMain(values.main, value.id);
                                          }}
                                        >
                                          <Field
                                            as="select"
                                            id="inputCreateDish"
                                            name="main"
                                            disabled
                                          >
                                            <option>{value.main}</option>

                                            {dishes.main.map((value, key) => {
                                              return (
                                                <option value={value.Name}>
                                                  {value.Name}
                                                </option>
                                              );
                                            })}
                                          </Field>
                                        </Formik>
                                      )}
                                    {value.guest === 1 &&
                                      !admin &&
                                      value.Guest.id !== authState.id && (
                                        <Formik
                                          initialValues={{
                                            main: value.main || null,
                                          }}
                                          validate={(values) => {
                                            changeMain(values.main, value.id);
                                          }}
                                        >
                                          <Field
                                            as="select"
                                            id="inputCreateDish"
                                            name="main"
                                          >
                                            <option>{value.main}</option>

                                            {dishes.main.map((value, key) => {
                                              return (
                                                <option value={value.Name}>
                                                  {value.Name}
                                                </option>
                                              );
                                            })}
                                          </Field>
                                        </Formik>
                                      )}
                                  </td>
                                )}

                                {eventObject.groupType === "Tables" && (
                                  <td>
                                    {value.guest === 0 &&
                                      (admin ||
                                        value.User.id === authState.id) && (
                                        <Formik
                                          initialValues={{
                                            desert: value.desert || null,
                                          }}
                                          validate={(values) => {
                                            changeDesert(
                                              values.desert,
                                              value.id
                                            );
                                          }}
                                        >
                                          <Field
                                            as="select"
                                            id="inputCreateDish"
                                            name="desert"
                                          >
                                            <option>{value.desert}</option>

                                            {dishes.desert.map((value, key) => {
                                              return (
                                                <option value={value.Name}>
                                                  {value.Name}
                                                </option>
                                              );
                                            })}
                                          </Field>
                                        </Formik>
                                      )}
                                    {value.guest === 1 &&
                                      (admin ||
                                        value.Guest.id === authState.id) && (
                                        <Formik
                                          initialValues={{
                                            desert: value.desert || null,
                                          }}
                                          validate={(values) => {
                                            changeDesert(
                                              values.desert,
                                              value.id
                                            );
                                          }}
                                        >
                                          <Field
                                            as="select"
                                            id="inputCreateDish"
                                            name="desert"
                                          >
                                            <option>{value.desert}</option>

                                            {dishes.desert.map((value, key) => {
                                              return (
                                                <option value={value.Name}>
                                                  {value.Name}
                                                </option>
                                              );
                                            })}
                                          </Field>
                                        </Formik>
                                      )}
                                    {value.guest === 0 &&
                                      !admin &&
                                      value.User.id !== authState.id && (
                                        <Formik
                                        initialValues={{
                                          desert: value.desert || null,
                                        }}
                                        validate={(values) => {
                                          changeDesert(
                                            values.desert,
                                            value.id
                                          );
                                        }}
                                      >
                                        <Field
                                          as="select"
                                          id="inputCreateDish"
                                          name="desert"
                                          disabled
                                        >
                                          <option>{value.desert}</option>

                                          {dishes.desert.map((value, key) => {
                                            return (
                                              <option value={value.Name}>
                                                {value.Name}
                                              </option>
                                            );
                                          })}
                                        </Field>
                                      </Formik>
                                      )}
                                    {value.guest === 1 &&
                                      !admin &&
                                      value.Guest.id !== authState.id && (
                                        <Formik
                                        initialValues={{
                                          desert: value.desert || null,
                                        }}
                                        validate={(values) => {
                                          changeDesert(
                                            values.desert,
                                            value.id
                                          );
                                        }}
                                      >
                                        <Field
                                          as="select"
                                          id="inputCreateDish"
                                          name="desert"
                                        >
                                          <option>{value.desert}</option>

                                          {dishes.desert.map((value, key) => {
                                            return (
                                              <option value={value.Name}>
                                                {value.Name}
                                              </option>
                                            );
                                          })}
                                        </Field>
                                      </Formik>
                                      )}
                                  </td>
                                )}
                                {/* Comments column */}
                                <CommentEdit user={value} admin={admin} updateComment={changeObservations} authState={authState}/>
                                <td>
                                  {value.guest === 0 && admin && (
                                    <i
                                      className="fas fa-minus-circle red"
                                      onClick={(e) =>
                                        removeParticipant(
                                          value.id,
                                          value.User.id,
                                          value.guest
                                        )
                                      }
                                    ></i>
                                  )}
                                  {value.guest === 1 && admin && (
                                    <i
                                      className="fas fa-minus-circle red"
                                      onClick={(e) =>
                                        removeParticipant(
                                          value.id,
                                          value.Guest.id,
                                          value.guest
                                        )
                                      }
                                    ></i>
                                  )}
                                  {value.guest === 0 &&
                                    !admin &&
                                    value.User.id === authState.id && (
                                      <i
                                        className="fas fa-minus-circle red"
                                        onClick={(e) =>
                                          removeParticipant(
                                            value.id,
                                            value.User.id,
                                            value.guest
                                          )
                                        }
                                      ></i>
                                    )}
                                  {value.guest === 1 &&
                                    !admin &&
                                    value.Guest.id === authState.id && (
                                      <i
                                        className="fas fa-minus-circle red"
                                        onClick={(e) =>
                                          removeParticipant(
                                            value.id,
                                            value.Guest.id,
                                            value.guest
                                          )
                                        }
                                      ></i>
                                    )}
                                </td>
                              </tr>
                            </>
                          );
                        })}
                        {eventObject.groupType !== "Tables" && (
                          <tr>
                            <td colSpan="4">&nbsp;</td>
                            <td>{showHcp()}</td>
                            <td colSpan="2">&nbsp;</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </Card.Body>
                  {!admin && (
                    <Card.Footer className="text-muted">
                      <Button variant="primary" onClick={enrollUserButton}>
                        Enrol
                      </Button>
                    </Card.Footer>
                  )}
                  {!admin && (
                    <Card.Footer className="text-muted">
                      <Button
                        variant="primary"
                        onClick={() =>
                          window.open(
                            `https://dev.scala4.com/epmailorganizer.php?Eventid=${id}&memberId=${authState.id}`,
                            "_blank"
                          )
                        }
                      >
                        Mail Organizer
                      </Button>
                    </Card.Footer>
                  )}
                  {admin && (
                    <Card.Footer>
                      <Button
                        variant="success"
                        onClick={reloadParticipantsListChanges}
                        className="mt-2"
                        size="sm"
                      >
                        Update
                      </Button>
                      {autoUpdate && (
                        <Button
                          variant="success"
                          onClick={() => toggleAutoUpdate()}
                          className="mt-2"
                          size="sm"
                        >
                          Auto-Update On
                        </Button>
                      )}
                      {!autoUpdate && (
                        <Button
                          variant="outline-success"
                          onClick={() => toggleAutoUpdate()}
                          className="mt-2"
                          size="sm"
                        >
                          Auto-Update Off
                        </Button>
                      )}
                      <Button
                        variant="warning"
                        onClick={() => autoGroupParticipants()}
                        className="mt-2"
                        size="sm"
                      >
                        Auto Grouping
                      </Button>

                      <Button
                        variant="success"
                        onClick={() =>
                          window.open(
                            `https://dev.scala4.com/epmenu.php?Eventid=${id}&memberId=${authState.id}`,
                            "_blank"
                          )
                        }
                        className="mt-2"
                        size="sm"
                      >
                        Settings
                      </Button>
                      <Button
                        variant="info"
                        onClick={() =>
                          window.open(
                            `https://dev.scala4.com/epmaillparticipants.php?Eventid=${id}&memberId=${authState.id}`,
                            "_blank"
                          )
                        }
                        className="mt-2 custom"
                        size="sm"
                      >
                        Mail Participants
                      </Button>

                      <Button
                        variant="info"
                        onClick={() =>
                          window.open(
                            `https://dev.scala4.com/epmailgroup.php?Eventid=${id}&memberId=${authState.id}`,
                            "_blank"
                          )
                        }
                        className="mt-2 custom"
                        size="sm"
                      >
                        Mail Group
                      </Button>
                      <ExcelFile
                        element={
                          <Button variant="info" className="mt-2" size="sm">
                            Export XLS
                          </Button>
                        }
                      >
                        <ExcelSheet data={dataSet} name="Participants">
                          <ExcelColumn label="NOMBRE1" value="name" />
                          <ExcelColumn label="LICENCIA1" value="federation" />
                          <ExcelColumn label="TEE" value="tee" />
                          <ExcelColumn label="HORA" value="startTime" />
                          <ExcelColumn label="HCP" value="hcp" />
                        </ExcelSheet>
                      </ExcelFile>
                      <Button
                        variant="info"
                        onClick={() => exportDocx()}
                        className="mt-2"
                        size="sm"
                      >
                        Export Docx
                      </Button>
                      {/* <PDFDownloadLink document={<MyDoc />} fileName='somename.pdf'>
                        {
                          <Button variant='danger' className='mt-2' size='sm'>
                            Export PDF
                          </Button>
                        }
                      </PDFDownloadLink> */}

                      <CSVLink
                        data={transactionData}
                        filename={`participants${today}.csv`}
                        className="hidden"
                        ref={csvLink}
                        target="_blank"
                      />
                    </Card.Footer>
                  )}
                </Card>
                <Card className="waitlistUsers mt-2">
                  <Card.Header>Waitlist</Card.Header>
                  <Card.Body>
                    <Table className="participantsTable" responsive borderless>
                      <colgroup>
                        <col span="1" className="width35" />
                        <col span="1" className="width10" />
                        <col span="1" className="width10" />
                        <col span="1" className="width10" />
                        <col span="1" className="width30" />
                        <col span="1" className="width5" />
                        <col span="1" className="width5" />
                        <col span="1" className="width5" />
                        <col span="1" className="width5" />
                        <col span="1" className="width5" />
                      </colgroup>
                      <thead>
                        <td
                          className="sortableColumn"
                          onClick={() => handleSort("name")}
                        >
                          Name
                          {sortMode[0] === "name" && sortMode[1] === "asc" && (
                            <i className="fas fa-arrow-up black" />
                          )}
                          {sortMode[0] === "name" && sortMode[1] === "desc" && (
                            <i className="fas fa-arrow-down black" />
                          )}
                        </td>
                        <td
                          className="sortableColumn"
                          onClick={() => handleSort("group")}
                        >
                          {eventObject.groupType}
                          {sortMode[0] === "group" && sortMode[1] === "asc" && (
                            <i className="fas fa-arrow-up black" />
                          )}
                          {sortMode[0] === "group" &&
                            sortMode[1] === "desc" && (
                              <i className="fas fa-arrow-down black" />
                            )}
                        </td>

                        {eventObject.groupType !== "Tables" && (
                          <td className="sortableColumn">Tee</td>
                        )}

                        {/* space for participant button */}
                        {admin &&
                          <td></td>
                        }

                        {eventObject.groupType !== "Tables" && (
                          <td
                            className="sortableColumn"
                            onClick={() => handleSort("hcp")}
                          >
                            HCP
                            {sortMode[0] === "hcp" && sortMode[1] === "asc" && (
                              <i className="fas fa-arrow-up black" />
                            )}
                            {sortMode[0] === "hcp" &&
                              sortMode[1] === "desc" && (
                                <i className="fas fa-arrow-down black" />
                              )}
                          </td>
                        )}

                        {eventObject.groupType === "Tables" && (
                          <td
                            className="sortableColumn"
                            onClick={() => handleSort("places")}
                          >
                            Places{" "}
                            {sortMode[0] === "places" &&
                              sortMode[1] === "asc" && (
                                <i className="fas fa-arrow-up black" />
                              )}
                            {sortMode[0] === "places" &&
                              sortMode[1] === "desc" && (
                                <i className="fas fa-arrow-down black" />
                              )}
                          </td>
                        )}
                        {eventObject.groupType === "Tables" && <td>Starter</td>}
                        {eventObject.groupType === "Tables" && <td>Main</td>}
                        {eventObject.groupType === "Tables" && <td>Desert</td>}
                        <td>Comments</td>
                        <td></td>

                      </thead>
                      <tbody>
                        {listOfEventUsersOnWaitlist.map((value, key) => {
                          return (
                            <>
                              {value.flight !== savedFlight && (
                                <>
                                  <tr className="spacer"></tr>
                                  <tr className="tableBorderTop">
                                    <td colSpan="5">
                                      {changeSavedFlight(value.flight)}
                                    </td>
                                  </tr>
                                  <tr className="spacer"></tr>
                                </>
                              )}

                              <tr key={value.id}>
                                {/* {value.guest === 0 && (
                                  <td>{value.User.name}</td>
                                )}
                                {value.guest === 1 && (
                                  <td>{value.Guest.name}</td>
                                )} */}
                                <EventNameEdit user={value} admin={admin}/>
                                
                                {eventObject.groupType === "Tables" && (
                                  <td>
                                    <input
                                      className="width45px"
                                      type="number"
                                      defaultValue={value.flight}
                                      min="0"
                                      max="9999"
                                      id={value.id}
                                      onChange={(e) =>
                                        changeFlight(e.target.value, value.id)
                                      }
                                    />
                                  </td>
                                )}
                                {eventObject.groupType !== "Tables" && (
                                  <td>
                                    <input
                                      type="number"
                                      className="width45px"
                                      defaultValue={value.flight}
                                      min="0"
                                      max="9999"
                                      id={value.id}
                                      disabled
                                    />
                                  </td>
                                )}

                                {eventObject.groupType === "Tables" && (
                                  <td>
                                    <input
                                      className="width45px"
                                      type="number"
                                      defaultValue={value.places}
                                      min="1"
                                      max="12"
                                      id={value.id}
                                      onChange={(e) =>
                                        changePlaces(e.target.value, value.id)
                                      }
                                      onClick={(e) =>
                                        handleClick(e, e.target.value)
                                      }
                                      onBlur={(e) =>
                                        handleBlur(e, e.target.value)
                                      }
                                    />
                                  </td>
                                )}
                                {eventObject.groupType === "Tables" && (
                                  <td>
                                    <Formik
                                      initialValues={{
                                        starter: value.starter || null,
                                      }}
                                      validate={(values) => {
                                        changeStarter(values.starter, value.id);
                                      }}
                                    >
                                      <Field
                                        as="select"
                                        id="inputCreateDish"
                                        name="starter"
                                      >
                                        <option>{value.starter}</option>

                                        {dishes.starter.map((value, key) => {
                                          return (
                                            <option value={value.Name}>
                                              {value.Name}
                                            </option>
                                          );
                                        })}
                                      </Field>
                                    </Formik>
                                  </td>
                                )}
                                {eventObject.groupType === "Tables" && (
                                  <td>
                                    <Formik
                                      initialValues={{
                                        main: value.main || null,
                                      }}
                                      validate={(values) => {
                                        changeMain(values.main, value.id);
                                      }}
                                    >
                                      <Field
                                        as="select"
                                        id="inputCreateDish"
                                        name="main"
                                      >
                                        <option>{value.main}</option>

                                        {dishes.main.map((value, key) => {
                                          return (
                                            <option value={value.Name}>
                                              {value.Name}
                                            </option>
                                          );
                                        })}
                                      </Field>
                                    </Formik>
                                  </td>
                                )}
                                {eventObject.groupType === "Tables" && (
                                  <td>
                                    <Formik
                                      initialValues={{
                                        desert: value.desert || null,
                                      }}
                                      validate={(values) => {
                                        changeDesert(values.desert, value.id);
                                      }}
                                    >
                                      <Field
                                        as="select"
                                        id="inputCreateDish"
                                        name="desert"
                                      >
                                        <option>{value.desert}</option>

                                        {dishes.desert.map((value, key) => {
                                          return (
                                            <option value={value.Name}>
                                              {value.Name}
                                            </option>
                                          );
                                        })}
                                      </Field>
                                    </Formik>
                                  </td>
                                )}

                                {eventObject.groupType !== "Tables" && (
                                  <td  className="text">{value.tee}</td>
                                )}
                                
                                {admin && 
                                  <td className="pb-1">
                                    <button onClick={() => changeEventUserWaitlist(value.id, false)} className="btn btn-success btn-sm">Participant</button>
                                  </td>
                                }

                                {eventObject.groupType !== "Tables" && (
                                  <td className="text">
                                    {value.guest === 0 && (
                                      <>
                                        {!admin 
                                          ? <td>{value.User.handicap}</td>
                                          : <EventHcpEdit user={value}/>
                                        }
                                      </>
                                    )}
                                    {value.guest === 1 && (
                                      <>
                                        {!admin 
                                          ? <td>{value.Guest.handicap}</td>
                                          : <EventHcpEdit user={value}/>
                                        }
                                      </>
                                    )}
                                  </td>
                                )}
                                {/* Comments column */}
                                <CommentEdit user={value} admin={admin} updateComment={changeObservations} authState={authState}/>

                                <td>
                                  {value.guest === 0 && admin && (
                                    <i
                                      className="fas fa-minus-circle red"
                                      onClick={(e) =>
                                        removeParticipant(
                                          value.id,
                                          value.User.id,
                                          value.guest
                                        )
                                      }
                                    ></i>
                                  )}
                                  {value.guest === 1 && admin && (
                                    <i
                                      className="fas fa-minus-circle red"
                                      onClick={(e) =>
                                        removeParticipant(
                                          value.id,
                                          value.Guest.id,
                                          value.guest
                                        )
                                      }
                                    ></i>
                                  )}
                                  {value.guest === 0 &&
                                    !admin &&
                                    value.User.id === authState.id && (
                                      <i
                                        className="fas fa-minus-circle red"
                                        onClick={(e) =>
                                          removeParticipant(
                                            value.id,
                                            value.User.id,
                                            value.guest
                                          )
                                        }
                                      ></i>
                                    )}
                                  {value.guest === 1 &&
                                    !admin &&
                                    value.Guest.id === authState.id && (
                                      <i
                                        className="fas fa-minus-circle red"
                                        onClick={(e) =>
                                          removeParticipant(
                                            value.id,
                                            value.Guest.id,
                                            value.guest
                                          )
                                        }
                                      ></i>
                                    )}
                                </td>
                                {value.flight !== savedFlight && (
                                  <td>
                                    {value.flight} {savedFlight}
                                  </td>
                                )}
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
                {admin && <SelectBox es6Function={es6Function} id={id} />}
                {admin && visibleAddGuestForm && (
                  <AddGuest es6Function={returnFromAddGuest} id={id} />
                )}
                {admin && (
                  <CSVReader
                    onDrop={handleOnDrop}
                    onError={handleOnError}
                    noClick
                    addRemoveButton
                    onRemoveFile={handleOnRemoveFile}
                    style={{
                      dropArea: {
                        marginTop: 30,
                        marginBottom: 30,
                        borderColor: "#ccc",
                        borderRadius: 20,
                        height: 140,
                      },
                      dropAreaActive: {
                        borderColor: "green",
                      },
                      dropFile: {
                        width: 150,
                        height: 120,
                        background: "#eee",
                      },
                      fileSizeInfo: {
                        color: "#333",
                        borderRadius: 3,
                        lineHeight: 1,
                        marginBottom: "0.5em",
                        padding: "0 0.4em",
                      },
                      fileNameInfo: {
                        color: "#333",
                        backgroundColor: "#eee",
                        borderRadius: 3,
                        fontSize: 14,
                        lineHeight: 1,
                        padding: "0 0.4em",
                      },
                      removeButton: {
                        color: "green",
                      },
                      progressBar: {
                        backgroundColor: "blue",
                      },
                    }}
                  >
                    <span>
                      Drop CSV file of participants here (format: Federation
                      Number on each line)
                    </span>
                  </CSVReader>
                )}
                {admin && (
                  <div {...getRootProps({ style })}>
                    <input {...getInputProps()} />
                    <p>
                      Drop XLS file of participants here (format: Federation
                      Number on each line)
                    </p>
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        )}
      {!loading &&
        !(
          checkInGroup(eventObject.groupNumber)
        ) && (
          <>
            <div role="alert" className="fade alert alert-danger show">
              You don't have access to this event. Please contact the
              administrator.
            </div>
            <Container className="eventPage">
              <Row>
                <Col>
                  <Card
                    key={eventObject.id}
                    className="text-center mb-3 event-details"
                  >
                    <Card.Header>
                      {new Date(eventObject.date).toLocaleDateString("es-ES")}{" "}
                      {String(eventObject.startTime).slice(0, 5)}
                    </Card.Header>
                    <Card.Body>
                      <Card.Title>{eventObject.event}</Card.Title>
                      <Card.Text>
                        {eventObject.type} | {eventObject.groupName}
                      </Card.Text>
                    </Card.Body>
                    <Card.Footer className="text-muted">
                      Total: {placesTotal} | Available: {placesAvailable} |
                      Occupied: {placesOccupied} | Waitlist: {placesWaitlist}
                    </Card.Footer>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card className="eventUsers">
                    <Card.Header>Participants</Card.Header>
                    <Card.Body>
                      <Table
                        className="participantsTable"
                        responsive
                        borderless
                      >
                        <colgroup>
                          <col span="1" className="width35" />
                          <col span="1" className="width10" />
                          <col span="1" className="width10" />
                          <col span="1" className="width10" />
                          <col span="1" className="width30" />
                          <col span="1" className="width5" />
                        </colgroup>
                        <thead>
                          <td>Name</td>
                          <td>{eventObject.groupType}</td>
                          {eventObject.groupType !== "Tables" && <td>Tee</td>}
                          {eventObject.groupType !== "Tables" && <td>HCP</td>}
                          <td>Comments</td>
                          <td></td>
                        </thead>
                        <tbody>
                          
                          {listOfEventUsers.map((value, key) => {
                            return (
                              <>
                                {value.flight !== savedFlight && (
                                  <>
                                    {savedFlight !== null && (
                                      <>
                                        {/* <tr className='spacer'></tr> */}
                                        
                                        <tr>
                                          <td colSpan="3">&nbsp;</td>
                                          {eventObject.groupType !== "Tables" && 
                                          <td>{showHcp()}</td>}
                                          <td colSpan="2">&nbsp;</td>
                                        </tr>
                                        <tr className="tableBorderTop">
                                          <td colSpan="5">{resetTotalHcp()}</td>
                                        </tr>
                                        <tr className="spacer"></tr>
                                      </>
                                        
                                    )}
                                    {changeSavedFlight(value.flight)}
                                  </>
                                )}

                                <tr key={value.id}>
                                  {value.guest === 0 && (
                                    <td>{value.User.name}</td>
                                  )}
                                  {value.guest === 1 && (
                                    <td>{value.Guest.name}</td>
                                  )}
                                  <td>
                                    {admin && (
                                      <input
                                        type="number"
                                        defaultValue={value.flight}
                                        min="0"
                                        max="9999"
                                        id={value.id}
                                        onChange={(e) =>
                                          changeFlight(e.target.value, value.id)
                                        }
                                        onClick={(e) =>
                                          handleClick(e, e.target.value)
                                        }
                                        onBlur={(e) =>
                                          handleBlur(e, e.target.value)
                                        }
                                      />
                                    )}
                                    {!admin && (
                                      <input
                                        type="number"
                                        defaultValue={value.flight}
                                        min="0"
                                        max="9999"
                                        id={value.id}
                                        disabled
                                      />
                                    )}
                                  </td>
                                  {eventObject.groupType !== "Tables" && (
                                    <td>
                                      {admin && (
                                        <input
                                          type="number"
                                          defaultValue={value.tee}
                                          min="1"
                                          max="9999"
                                          id={value.id}
                                          onChange={(e) =>
                                            changeTee(e.target.value, value.id)
                                          }
                                          onClick={(e) =>
                                            handleClick(e, e.target.value)
                                          }
                                          onBlur={(e) =>
                                            handleBlur(e, e.target.value)
                                          }
                                        />
                                      )}
                                      {!admin && (
                                        <input
                                          type="number"
                                          defaultValue={value.tee}
                                          min="1"
                                          max="9999"
                                          id={value.id}
                                          disabled
                                        />
                                      )}
                                    </td>
                                  )}
                                  {eventObject.groupType !== "Tables" && (
                                    <td>
                                      {value.guest === 0 && (
                                        <>
                                          <td>{value.User.handicap}</td>
                                          <td className="hidden">
                                            {addHcp(value.User.handicap)}
                                          </td>
                                        </>
                                      )}
                                      {value.guest === 1 && (
                                        <>
                                          <td>{value.Guest.handicap}</td>
                                          <td className="hidden">
                                            {addHcp(value.Guest.handicap)}
                                          </td>
                                        </>
                                      )}
                                    </td>
                                  )}
                                  {/* Comments column */}
                                  <CommentEdit user={value} admin={admin} updateComment={changeObservations} authState={authState}/>

                                  <td>
                                    {value.guest === 0 && admin && (
                                      <i
                                        className="fas fa-minus-circle red"
                                        onClick={(e) =>
                                          removeParticipant(
                                            value.id,
                                            value.User.id,
                                            value.guest
                                          )
                                        }
                                      ></i>
                                    )}
                                    {value.guest === 1 && admin && (
                                      <i
                                        className="fas fa-minus-circle red"
                                        onClick={(e) =>
                                          removeParticipant(
                                            value.id,
                                            value.Guest.id,
                                            value.guest
                                          )
                                        }
                                      ></i>
                                    )}
                                    {value.guest === 0 &&
                                      !admin &&
                                      value.User.id === authState.id && (
                                        <i
                                          className="fas fa-minus-circle red"
                                          onClick={(e) =>
                                            removeParticipant(
                                              value.id,
                                              value.User.id,
                                              value.guest
                                            )
                                          }
                                        ></i>
                                      )}
                                    {value.guest === 1 &&
                                      !admin &&
                                      value.Guest.id === authState.id && (
                                        <i
                                          className="fas fa-minus-circle red"
                                          onClick={(e) =>
                                            removeParticipant(
                                              value.id,
                                              value.Guest.id,
                                              value.guest
                                            )
                                          }
                                        ></i>
                                      )}
                                  </td>
                                  {value.flight !== savedFlight && (
                                    <td>
                                      {value.flight} {savedFlight}
                                    </td>
                                  )}
                                </tr>
                              </>
                            );
                          })}
                          <tr>
                            <td colSpan="3">&nbsp;</td>
                            {eventObject.groupType !== "Tables" && 
                                          <td>{showHcp()}</td>}
                            
                            <td colSpan="2">&nbsp;</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Card.Body>
                  </Card>
                  <Card className="waitlistUsers mt-2">
                    <Card.Header>Waitlist</Card.Header>
                    <Card.Body>
                      <Table
                        className="participantsTable"
                        responsive
                        borderless
                      >
                        <colgroup>
                          <col span="1" className="width35" />
                          <col span="1" className="width10" />
                          <col span="1" className="width10" />
                          <col span="1" className="width10" />
                          <col span="1" className="width30" />
                          <col span="1" className="width5" />
                        </colgroup>
                        <thead>
                          <td>Name</td>
                          <td>{eventObject.groupType}</td>
                          {eventObject.groupType !== "Tables" && <td>Tee</td>}
                          {eventObject.groupType !== "Tables" && <td>HCP</td>}

                          <td>Comments</td>
                          <td></td>
                        </thead>
                        <tbody>
                          {listOfEventUsersOnWaitlist.map((value, key) => {
                            return (
                              <>
                                {value.flight !== savedFlight && (
                                  <>
                                    <tr className="spacer"></tr>
                                    <tr className="tableBorderTop">
                                      <td colSpan="5">
                                        {changeSavedFlight(value.flight)}
                                      </td>
                                    </tr>
                                    <tr className="spacer"></tr>
                                  </>
                                )}

                                <tr key={value.id}>
                                  {value.guest === 0 && (
                                    <td>{value.User.name}</td>
                                  )}
                                  {value.guest === 1 && (
                                    <td>{value.Guest.name}</td>
                                  )}
                                  <td>
                                    {admin ||
                                      (value.id === authState.id && (
                                        <input
                                          type="number"
                                          defaultValue={value.flight}
                                          min="0"
                                          max="9999"
                                          id={value.id}
                                          onChange={(e) =>
                                            changeFlight(
                                              e.target.value,
                                              value.id
                                            )
                                          }
                                          onClick={(e) =>
                                            handleClick(e, e.target.value)
                                          }
                                          onBlur={(e) =>
                                            handleBlur(e, e.target.value)
                                          }
                                        />
                                      ))}
                                    <input
                                      type="number"
                                      defaultValue={value.flight}
                                      min="0"
                                      max="9999"
                                      id={value.id}
                                      disabled
                                    />
                                  </td>
                                  {eventObject.groupType !== "Tables" && (
                                    <td className="width">
                                      {value.guest === 0 && (
                                        <td>{value.User.handicap}</td>
                                      )}
                                      {value.guest === 1 && (
                                        <td>{value.Guest.handicap}</td>
                                      )}
                                    </td>
                                  )}
                                  {eventObject.groupType !== "Tables" && (
                                    <td className="width">{value.tee}</td>
                                  )}

                                  {/* Comments column */}
                                  <CommentEdit user={value} admin={admin} updateComment={changeObservations} authState={authState}/>

                                  <td>
                                    {value.guest === 0 && admin && (
                                      <i
                                        className="fas fa-minus-circle red"
                                        onClick={(e) =>
                                          removeParticipant(
                                            value.id,
                                            value.User.id,
                                            value.guest
                                          )
                                        }
                                      ></i>
                                    )}
                                    {value.guest === 1 && admin && (
                                      <i
                                        className="fas fa-minus-circle red"
                                        onClick={(e) =>
                                          removeParticipant(
                                            value.id,
                                            value.Guest.id,
                                            value.guest
                                          )
                                        }
                                      ></i>
                                    )}
                                    {value.guest === 0 &&
                                      !admin &&
                                      value.User.id === authState.id && (
                                        <i
                                          className="fas fa-minus-circle red"
                                          onClick={(e) =>
                                            removeParticipant(
                                              value.id,
                                              value.User.id,
                                              value.guest
                                            )
                                          }
                                        ></i>
                                      )}
                                    {value.guest === 1 &&
                                      !admin &&
                                      value.Guest.id === authState.id && (
                                        <i
                                          className="fas fa-minus-circle red"
                                          onClick={(e) =>
                                            removeParticipant(
                                              value.id,
                                              value.Guest.id,
                                              value.guest
                                            )
                                          }
                                        ></i>
                                      )}
                                  </td>
                                  {value.flight !== savedFlight && (
                                    <td>
                                      {value.flight} {savedFlight}
                                    </td>
                                  )}
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                      </Table>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </>
        )}
      {loading && (
        <Container className="createEventPage">
          <Oval color="#0c7b49" height={40} width={40} ariaLabel="loading" />
        </Container>
      )}
    </>
  );
}

export default Event;
