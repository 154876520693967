import axios from "axios";
import React, { useEffect, useState } from "react";
const { REACT_APP_API_URL } = process.env;

export default function EventHcpEdit({ user }) {
	// const variables
	const hcp = (user.guest === 1) ? user.Guest.handicap : user.User.handicap;
	const inputStyle = {
		maxWidth: "55px",
	};

	// useState variables
	const [value, setValue] = useState(hcp);
	const [processing, setProcessing] = useState(false);
	const [error, setError] = useState(null);

	// functions
	function changeHandler(e) {
		const value = e.target.value;

		if (/[a-zA-Z]/g.test(value)) return; // check if string contains any letters

		setValue(value);

		if (error) setError(null);
	}

	function editHCP(value) {
		if (value === "" || value === null) {
			setError("cannot be empty");
			return;
		}

		setProcessing(true);

		axios.put(`${REACT_APP_API_URL}users/edithcp`, {
			guest: user.guest,
			userId: (user.guest === 1) ? user.GuestId : user.UserId,
			hcp: value,
		}, {
			headers: {
				accessToken: localStorage.getItem("accessToken"),
			},
		}).then((res) => {
			console.log(res.data);
			setProcessing(false);
		});
	}

	// useEffects
	// Use effect to detect for when user stops writing, so we can send a request to the backend
	useEffect(() => {
		// check, if current value is the same as start value, then do nothing
		if (value === hcp) return;

		const timeoutID = setTimeout(() => {
			editHCP(value);
		}, 2000);
		return () => clearTimeout(timeoutID);
	}, [value]);

	return (
		<td>
			<input
				disabled={processing}
				value={value}
				style={error ? { ...inputStyle, border: "1px solid red" } : inputStyle}
				onChange={changeHandler}
			/>
			{error && <p style={{ color: "red" }}>{error}</p>}
		</td>
	);
}
